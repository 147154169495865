<template>
  <v-row>
    <v-dialog v-model="dialogIndividu" persistent max-width="400px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Fase Individu</span>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">Waktu mulai</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_"
                    label="Waktu Mulai Pelatihan"
                    prepend-icon="mdi-calendar"
                    :error-messages="startErr"
                    @input="$v.start_.$touch()"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  :min="minStart"
                  @change="vialiDate(start_date, now, '._start')"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">Waktu berakhir</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_"
                    label="Waktu Berakhir Pelatihan"
                    prepend-icon="mdi-calendar"
                    :error-messages="endErr"
                    @input="$v.end_.$touch()"
                    readonly
                    :disabled="disableDate"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  :min="minEnd"
                  @input="menu2 = false"
                  @change="vialiDate(end_date, start_date, '._end')"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark v-if="loading">
            <v-progress-circular
              :size="30"
              :width="4"
              color="white"
              indeterminate
              class="mb-2"
            ></v-progress-circular>
          </v-btn>
          <v-btn color="indigo" dark @click="updateEvent" v-else>
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogIndividuUpdate" persistent max-width="400px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Fase Individu</span>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">Waktu mulai</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time_start_individu"
                    label="Waktu Mulai Pelatihan"
                    prepend-icon="mdi-calendar"
                    :error-messages="startErr"
                    @input="$v.time_start_individu.$touch()"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  :min="minStart"
                  @change="vialiDate(start_date, now, '._start')"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">Waktu berakhir</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time_end_individu"
                    label="Waktu Berakhir Pelatihan"
                    prepend-icon="mdi-calendar"
                    :error-messages="endErr"
                    @input="$v.time_end_individu.$touch()"
                    readonly
                    :disabled="disableDate"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  :min="minEnd"
                  @input="menu2 = false"
                  @change="vialiDate(end_date, start_date, '._end')"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark v-if="loading">
            <v-progress-circular
              :size="30"
              :width="4"
              color="white"
              indeterminate
              class="mb-2"
            ></v-progress-circular>
          </v-btn>
          <v-btn color="indigo" dark @click="updateEvent" v-else>
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { UPDATE_EVENT, EVENT_BY_ID } from "../../../graphql/Event";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import moment from "moment";

export default {
  name: "addFaseIndividu",
  apollo: {
    pelatihanById: {
      variables() {
        return { id: this.router };
      },
      query: EVENT_BY_ID
    }
  },

  mixins: [validationMixin],
  validations: {
    start_date: { required },
    end_date: { required }
  },
  computed: {
    minStart() {
      return moment(this.now, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    minEnd() {
      return moment(this.start_date, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    startDateKelompok() {
      return moment(this.pelatihanById.start_date_kelompok, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    startErr() {
      const errors = [];
      if (!this.$v.start_date.$dirty) return errors;
      !this.$v.start_date.required &&
        errors.push("Tangggal Pelatihan harus di isi!");
      return errors;
    },
    endErr() {
      const errors = [];
      if (!this.$v.end_date.$dirty) return errors;
      !this.$v.end_date.required &&
        errors.push("Tangggal Pelatihan harus di isi!");
      return errors;
    }
  },
  props: [
    "dialogIndividu",
    "dialogIndividuUpdate",
    "router",
    "time_start_individu",
    "time_end_individu"
  ],
  data: () => ({
    id: null,
    start_date: "",
    end_date: "",
    end_: "",
    menu2: false,
    start_: "",
    now: new Date().toISOString().slice(0, 10),
    menu1: false,
    disableDate: true,
    loading: false
  }),
  watch: {
    start_date(val) {
      if (val !== "") {
        this.disableDate = false;
      }
    }
  },
  mounted() {},
  methods: {
    vialiDate(a, b, c) {
      if (c == "._start") {
        if (this.dialogIndividuUpdate) {
          this.time_start_individu = moment(a).format("DD-MM-YYYY");
          this.disableDate = true;
        } else {
          this.start_ = moment(a).format("DD-MM-YYYY");
        }
      } else {
        if (this.dialogIndividuUpdate) {
          this.time_end_individu = moment(a).format("DD-MM-YYYY");
        } else {
          this.end_ = moment(a).format("DD-MM-YYYY");
        }
      }
    },
    updateEvent() {
      this.$v.start_date.$touch();
      this.$v.end_date.$touch();
      if (!this.$v.start_date.required && !this.$v.end_date.required) {
        return;
      } else {
        this.loading = true;
        const id = parseInt(this.router);
        const start_date_individu = this.start_date;
        const end_date_individu = this.end_date;
        const nama = this.pelatihanById.nama;
        const start_date_kelompok = moment(this.end_date)
          .add(1, "days")
          .format("YYYY-MM-DD");
        console.log(start_date_kelompok);
        this.$apollo
          .mutate({
            mutation: UPDATE_EVENT,
            variables: {
              id: id,
              nama: nama,
              start_date_individu: start_date_individu,
              end_date_individu: end_date_individu,
              start_date_kelompok: start_date_kelompok
            }
          })
          .then(() => {
            this.$router.go();
            if (this.pelatihanById.modul.tipe == "INDIVIDUKELOMPOK") {
              this.pelatihanById.start_date_kelompok = this.startDateKelompok;
            }
            this.$apollo.queries.pelatihanById.refetch();
            this.date = "";
            this.loading = false;
            Swal.fire({
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            }).then(() => {
              this.$emit("false");
              this.$v.$reset();
            });
          });
      }
    }
  }
};
</script>

<style scoped></style>
