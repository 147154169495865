<template>
  <div class="bg-default pa-3 max-content">
    <nav class="mb-5" v-if="!grupById.mentor">
      <v-btn text color="indigo" @click="dialog = true">
        Tambah coach
        <v-btn color="indigo" fab small dark class="ml-2"
          ><v-icon dark small>mdi-plus</v-icon></v-btn
        >
      </v-btn>
      <SelectMentor
        v-bind:dialog="dialog"
        v-bind:idGroup="idGroup"
        v-on:false="dialogFalse(1)"
      />
    </nav>
    <div class="d-flex">
      <div class="card-info ma-2">
        <div>
          <div
            class="indigo white--text rounded pa-2 mb-2"
            v-if="grupById.mentor"
          >
            <h4>{{ grupById.mentor.nama_lengkap }}</h4>
          </div>
          <div></div>
        </div>
        <div class="temp-card pa-2 mb-3">
          <div
            class="d-flex justify-space-between align-center indigo white--text rounded-lg pa-1"
          >
            <h3 class="pl-2">Tambah Peserta</h3>
            <v-btn text dark>
              <v-icon dark class="mr-2">mdi-account-multiple-plus</v-icon>
            </v-btn>
          </div>
          <form @submit.prevent="createStudent(2)" class="pa-2">
            <v-text-field
              v-model="namaPeserta"
              :counter="100"
              :error-messages="pesertaError"
              label="Nama Lengkap"
              required
              @input="$v.namaPeserta.$touch()"
              @blur="$v.namaPeserta.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="emailPeserta"
              label="Email"
              :error-messages="emailPesertaError"
              counter
              @input="$v.emailPeserta.$touch()"
              @blur="$v.emailPeserta.$touch()"
            ></v-text-field>
            <div class="button-group mt-5 d-flex flex-column">
              <v-btn class="mb-3" type="createStudent" color="#1a237e" dark>
                <span v-if="loading" class="d-flex">
                  <Loading propsColor="white" />
                </span>
                <span v-else> Tambah </span>
              </v-btn>
            </div>
            <v-snackbar v-model="snack" color="red">
              {{ errMsg }}
              <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="closeSnack">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </form>
        </div>
        <div class="temp-card pa-2 mb-3">
          <div class="white--text rounded-lg pa-1">
            <v-btn
              class="mb-3"
              color="#1a237e"
              dark
              :href="`${env}/storage/import/student.xlsx`"
              target="_blank"
              @click="importpeserta = true"
            >
              <span class="d-flex justify-space-between">
                Template Peserta
              </span>
              <v-icon color="white"> mdi-download-circle </v-icon>
            </v-btn>
            <v-file-input
              v-model="import_file"
              label="Import Peserta"
              v-if="importpeserta == true"
            ></v-file-input>
            <v-card-actions v-if="importpeserta == true">
              <v-btn color="success" class="mr-4" @click="importPeserta()">
                Impor
              </v-btn>
              <v-btn color="error" class="mr-4" @click="importpeserta = false">
                Batal
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
      <div class="width ma-2">
        <v-card class="temp-table">
          <v-card-title>
            Daftar Peserta
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div>
            <v-data-table
              :loading="loadTable"
              :headers="headers"
              :items="grupById.user"
              :search="search"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-fle">
                  <v-btn fab dark small color="cyan">
                    <v-icon dark small @click="editPage(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="red"
                    class="ml-3"
                    @click.prevent="deleteConfirm(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </div>
      <v-card class="profile-teacher ma-2">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-form ref="form" lazy-validation class="pa-3">
          <section>
            <small>Nama Lengkap</small>
            <p>{{ profile.nama_lengkap }}</p>
          </section>
          <section>
            <small>Nama Pengguna</small>
            <p>{{ profile.name }}</p>
          </section>
          <v-text-field
            v-model="profile.email"
            :counter="100"
            label="Email"
            required
          ></v-text-field>

          <v-card-actions>
            <div>
              <v-btn color="indigo" dark class="ma-2" @click="updatePeserta">
                resend email
              </v-btn>
              <v-btn color="red" dark class="ma-2" @click="close">
                Batal
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
      <EditPeserta
        v-bind:profile="profile"
        v-bind:idProfile="idProfile"
        v-bind:dialogView="dialogView"
        v-bind:compareEmail="compareEmail"
        v-on:false="dialogFalse(2)"
      />
    </div>
  </div>
</template>

<script>
import { GROUP_BY_ID } from "../../graphql/Event";
import {
  ADD_STUDENT,
  EDIT_STUDENT,
  DELETE_STUDENT
} from "../../graphql/Student";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import Loading from "../_base/loader";
import Swal from "sweetalert2";
import SelectMentor from "./Modal/selectMentor";
import { IMPORT_PESERTA } from "../../graphql/Teacher";
import EditPeserta from "./Modal/editStudent";

export default {
  name: "autoCreateGroup",
  apollo: {
    grupById: {
      variables() {
        return { id: this.idGroup };
      },
      query: GROUP_BY_ID,
      error(error) {
        console.error(error);
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    namaPeserta: { required, maxLength: maxLength(100) },
    emailPeserta: { required, email }
  },
  components: {
    Loading,
    SelectMentor,
    EditPeserta
  },
  props: ["idGroup"],
  computed: {
    // idGroup() {
    //   return this.grupById.id
    // },
    loadTable() {
      return this.$apollo.queries.grupById.loading;
    },
    pesertaError() {
      const errors = [];
      if (!this.$v.namaPeserta.$dirty) return errors;
      !this.$v.namaPeserta.required && errors.push("Nama harus di isi!");
      !this.$v.namaPeserta.maxLength &&
        errors.push("Nama hanya beoleh 100 karakter!");
      return errors;
    },
    emailPesertaError() {
      const errors = [];
      if (!this.$v.emailPeserta.$dirty) return errors;
      !this.$v.emailPeserta.email && errors.push("Email tidak valid");
      !this.$v.emailPeserta.required && errors.push("Email harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      namaPeserta: "",
      env: process.env.VUE_APP_GRAPHQL,
      emailPeserta: "",
      grupById: [],
      dialog: false,
      dialogView: false,
      loading: false,
      search: "",
      compareEmail: "",
      import_file: null,
      detailStudent: false,
      importpeserta: false,
      value: 0,
      headers: [
        { text: "id", value: "id" },
        {
          text: "Nama Lengkap",
          align: "start",
          sortable: false,
          value: "nama_lengkap"
        },
        { text: "Aksi", value: "actions" }
      ],
      profile: [],
      errMsg: "",
      snack: false
    };
  },
  methods: {
    editPage(item) {
      this.profile = item;
      this.compareEmail = item.email;
      this.dialogView = true;
    },

    dialogFalse(q) {
      switch (q) {
        case 1:
          this.dialog = false;
          break;
        case 2:
          this.dialogView = false;
          break;
      }
    },
    createStudent() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        const nama_lengkap = this.namaPeserta;
        const email = this.emailPeserta;
        const grup_id = parseInt(this.idGroup);
        const role_id = "STUDENT";
        const created_by = parseInt(localStorage.getItem("id"));
        this.$apollo
          .mutate({
            mutation: ADD_STUDENT,
            variables: {
              nama_lengkap: nama_lengkap,
              email: email,
              grup_id: grup_id,
              role_id: role_id,
              created_by: created_by
            }
          })
          .then(() => {
            this.loading = false;
            this.$apollo.queries.grupById.refetch();
            this.$v.namaPeserta.$reset();
            this.$v.emailPeserta.$reset();
            [this.namaPeserta, this.emailPeserta] = "";
          })
          .catch(error => {
            const err = Object.values(
              error.graphQLErrors[0].extensions.validation
            );
            this.errMsg = err[0][0];
            this.snack = true;
            this.loading = false;
          });
      }
    },
    closeSnack() {
      this.snack = false;
    },
    updatePeserta() {
      const id = parseInt(this.profile.id);
      const id_profile = parseInt(this.grupById.teacher_id);
      this.$apollo
        .mutate({
          mutation: EDIT_STUDENT,
          variables: {
            id: id,
            nama_lengkap: this.profile.nama_lengkap,
            email: this.profile.email,
            id_profile: id_profile,
            connect: this.profile.id
          }
        })
        .then(() => {
          this.$apollo.queries.grupById.refetch();
          this.$store.dispatch("resendEmail", {
            id: parseInt(this.profile.id)
          });
          this.close();
          Swal.fire({
            icon: "success",
            title: "Profile telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    importPeserta() {
      const file = this.import_file;
      const id = parseInt(this.grupById.teacher_id);
      console.log(this.grupById.teacher_id);
      this.$apollo
        .mutate({
          mutation: IMPORT_PESERTA,
          variables: {
            id: id,
            import: file
          },
          context: {
            hasUpload: true
          }
        })
        .then(data => {
          console.log(data);
          this.$apollo.queries.grupById.refetch();
        })
        .catch(err => {
          console.log(err.graphQLErrors);
          this.loader = false;
          console.log("hai kamu");
        });
    },
    deleteConfirm(item) {
      Swal.fire({
        title: "Yakin hapus peserta?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_STUDENT,
              variables: {
                id: item.id
              }
            })
            .then(() => {
              this.$apollo.queries.grupById.refetch();
              Swal.fire({
                icon: "success",
                title: "Peserta telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.max-content {
  height: max-content !important;
}
.bg-default {
  background: #f4f6f9;
}
.width {
  width: 70%;
  transition: 0.3s;
}
.temp-table .card-info {
  width: 25%;
  border-radius: 8px;
  height: max-content;
}
.temp-card {
  border-radius: 8px;
  box-shadow: 0px 0px 20px -9px rgba(26, 35, 126, 0.81);
  background: #fff;
  height: max-content;
}
.profile-teacher {
  display: block;
  width: 0;
  overflow: hidden;
  height: max-content;
}
</style>
