<template>
  <v-row justify="start">
    <v-dialog v-model="dialogGroup" persistent max-width="400px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Kelompok</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  v-if="undefined !== allMentor.data && allMentor.data.length"
                >
                  <v-text-field
                    label="Nama Kelompok"
                    :error-messages="errMsg"
                    required
                    :counter="50"
                    v-model="namaGroup"
                    @input="$v.namaGroup.$touch()"
                    @blur="$v.namaGroup.$touch()"
                  ></v-text-field>
                  <v-select
                    :items="allMentor.data"
                    v-model="Mentor"
                    item-text="nama_lengkap"
                    item-value="id"
                    label="Pilih coach"
                    :error-messages="mentorErr"
                    required
                    @input="$v.Mentor.$touch()"
                    @blur="$v.Mentor.$touch()"
                  ></v-select>
                </div>
                <div v-else>
                  <v-alert
                    class="ma-0 pa-2"
                    border="left"
                    colored-border
                    color="grey"
                  >
                    <p class="grey--text mb-0 ml-2">
                      Untuk membuat kelompok Anda di haruskan memiliki coach
                      terlebih dahulu
                    </p>
                  </v-alert>
                  <router-link to="/management" class="no-decor">
                    <v-btn text color="indigo"> Tambah coach sekarang. </v-btn>
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
          <span v-if="undefined !== allMentor.data && allMentor.data.length">
            <v-btn color="indigo" dark @click="addGroup"> Tambah </v-btn>
          </span>
        </v-card-actions>
      </v-card>
      <!-- <DialogLoading/> -->
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { CREATE_GROUP, EVENT_BY_ID } from "../../../graphql/Event";
import { GET_MENTOR } from "../../../graphql/Mentor";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
// import dialogLoading from '../../_base/dialogLoading.vue';
export default {
  name: "addGroup",

  apollo: {
    allMentor: {
      query: GET_MENTOR,
      variables() {
        return { page: 1, first: 10, search: this.find };
      },
      error(err) {
        console.log(err);
      }
    },
    pelatihanById: {
      variables() {
        return { id: this.router };
      },
      query: EVENT_BY_ID
    }
  },

  mixins: [validationMixin],
  components: {
    // dialogLoading,
  },
  validations: {
    namaGroup: { required, maxLength: maxLength(50) },
    Mentor: { required }
  },

  props: ["dialogGroup", "router"],

  computed: {
    errMsg() {
      const errors = [];
      if (!this.$v.namaGroup.$dirty) return errors;
      !this.$v.namaGroup.maxLength &&
        errors.push("Nama Kelompok maksimal 50 karakter!");
      !this.$v.namaGroup.required && errors.push("Nama Kelompok harus di isi!");
      return errors;
    },
    mentorErr() {
      const errors = [];
      if (!this.$v.Mentor.$dirty) return errors;
      !this.$v.Mentor.required && errors.push("Coach harus di isi!");
      return errors;
    }
  },
  data: () => ({
    id: null,
    namaGroup: "",
    show: false,
    find: "",
    allMentor: [],
    Mentor: "",
    items: [
      {
        nama: "sfdsdf"
      }
    ]
  }),
  methods: {
    addGroup() {
      this.$v.namaGroup.$touch();
      this.$v.Mentor.$touch();
      if (!this.$v.namaGroup.required && this.$v.namaGroup.maxLength <= 50) {
        return;
      } else if (!this.$v.Mentor.required) {
        console.log("Mentornya kemana?");
        return;
      } else {
        const nama = this.namaGroup;
        const teacher_id = parseInt(localStorage.getItem("id"));
        const pelatihan_id = parseInt(this.router);
        const mentor_id = parseInt(this.Mentor);
        this.$apollo
          .mutate({
            mutation: CREATE_GROUP,
            variables: {
              nama: nama,
              teacher_id: teacher_id,
              pelatihan_id: pelatihan_id,
              mentor_id: mentor_id
            }
          })
          .then(data => {
            console.log(data.data.createGrup);
            this.$apollo.queries.pelatihanById.refetch();
            this.namaGroup = "";
            this.Mentor = "";
            this.$emit("false");
            this.$v.namaGroup.$reset();
            this.$router.push(`/group-detail/${data.data.createGrup.id}`);
            Swal.fire({
              icon: "success",
              title: "Anda telah menambahkan kelompok baru",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
              toast: true
            });
          });
      }
    }
  }
};
</script>

<style></style>
