<template>
  <v-row>
    <v-dialog v-model="HintWiksa" scrollable persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Petunjuk Permainan</h3>
          <v-spacer></v-spacer>
          <h4 v-if="!close">Silakan tunggu... {{ counting }}</h4>
          <v-btn text v-if="close" @click="$emit('false')"
            >tutup <v-icon color="red">mdi-close keluar</v-icon></v-btn
          >
        </v-toolbar>
        <div class="pa-5 d-flex align-center flex-column">
          <p>
            Pada permainan ini Anda diharuskan mengisi soal teka teki silang
            secara bergantian dengan rekan tim Anda. Klik kolom jawaban dan
            tuliskan jawaban sesuai dengan giliran Anda.
          </p>
          <img src="../../../assets/img/wiksaView.jpeg" height="300px" alt="" />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "WiksaHint",
  props: ["HintWiksa"],
  data() {
    return {
      close: false,
      counting: null
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    }
  }
};
</script>

<style></style>
