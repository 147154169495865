<template>
  <v-row>
    <v-dialog v-model="dialogKelompok" persistent max-width="400px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Fase kelompok</span>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">Waktu mulai</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_"
                    label="Waktu Mulai Pelatihan"
                    prepend-icon="mdi-calendar"
                    :disabled="disableDate"
                    :error-messages="startErr"
                    @input="$v.start_.$touch()"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  :min="minStart"
                  @change="vialiDate(start_date, now, '._start')"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">Waktu berakhir</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_"
                    label="Waktu Berakhir Pelatihan"
                    prepend-icon="mdi-calendar"
                    :error-messages="endErr"
                    @input="$v.end_.$touch()"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  :min="minEnd"
                  @input="menu2 = false"
                  @change="vialiDate(end_date, start_date, '._end')"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark v-if="loading">
            <v-progress-circular
              :size="30"
              :width="4"
              color="white"
              indeterminate
              class="mb-2"
            ></v-progress-circular>
          </v-btn>
          <v-btn color="indigo" dark @click="updateEvent" v-else>
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogKelompokUpdate" persistent max-width="400px">
      <v-card>
        <v-toolbar color="indigo">
          <span class="headline white--text">Fase kelompok</span>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">Waktu mulai</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time_start_kelompok"
                    label="Waktu Mulai Pelatihan"
                    prepend-icon="mdi-calendar"
                    :disabled="disableDate"
                    :error-messages="startErr"
                    @input="$v.time_start_kelompok.$touch()"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  :min="minStart"
                  @change="vialiDate(start_date, now, '._start')"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">Waktu berakhir</v-col>
            <v-col cols="12" sm="8">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time_end_kelompok"
                    label="Waktu Berakhir Pelatihan"
                    prepend-icon="mdi-calendar"
                    :error-messages="endErr"
                    @input="$v.time_end_kelompok.$touch()"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  :min="minEnd"
                  @input="menu2 = false"
                  @change="vialiDate(end_date, start_date, '._end')"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark v-if="loading">
            <v-progress-circular
              :size="30"
              :width="4"
              color="white"
              indeterminate
              class="mb-2"
            ></v-progress-circular>
          </v-btn>
          <v-btn color="indigo" dark @click="updateEvent" v-else>
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import "vue2-datepicker/index.css";
import moment from "moment";

import { UPDATE_EVENT, EVENT_BY_ID } from "../../../graphql/Event";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "addFaseKelompok",

  apollo: {
    pelatihanById: {
      variables() {
        return { id: this.router };
      },
      result(data) {
        if (data.data.pelatihanById.end_date_individu) {
          this.start_ = moment(
            this.pelatihanById.end_date_individu,
            "DD-MM-YYYY"
          )
            .add(1, "days")
            .format("DD-MM-YYYY");
          this.disableDate = true;
          this.start_date = moment(
            this.pelatihanById.end_date_individu,
            "DD-MM-YYYY"
          )
            .add(1, "days")
            .format("YYYY-MM-DD");
        }
      },
      query: EVENT_BY_ID
    }
  },
  mixins: [validationMixin],
  validations: {
    start_date: { required },
    end_date: { required }
  },
  computed: {
    startErr() {
      const errors = [];
      if (!this.$v.start_date.$dirty) return errors;
      !this.$v.start_date.required &&
        errors.push("Tangggal Pelatihan harus di isi!");
      return errors;
    },
    minStart() {
      return moment(this.now, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    minEnd() {
      return moment(this.start_date, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    endErr() {
      const errors = [];
      if (!this.$v.end_date.$dirty) return errors;
      !this.$v.end_date.required &&
        errors.push("Tangggal Pelatihan harus di isi!");
      return errors;
    }
  },
  props: [
    "dialogKelompok",
    "dialogKelompokUpdate",
    "router",
    "time_end_kelompok",
    "time_start_kelompok"
  ],
  data() {
    return {
      id: null,
      start_date: "",
      end_date: "",
      end_: "",
      start_: "",
      now: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      disableDate: false,
      loading: false
    };
  },
  mounted() {
    this.now = moment(this.now).format("DD-MM-YYYY");
  },
  methods: {
    vialiDate(a, b, c) {
      if (c == "._start") {
        if (this.dialogKelompokUpdate) {
          this.time_start_kelompok = moment(a).format("DD-MM-YYYY");
          this.disableDate = true;
        } else {
          this.start_ = moment(a).format("DD-MM-YYYY");
          this.disableDate = true;
        }
      } else {
        if (this.dialogKelompokUpdate) {
          this.time_end_kelompok = moment(a).format("DD-MM-YYYY");
        } else {
          this.end_ = moment(a).format("DD-MM-YYYY");
        }
      }
    },
    updateEvent() {
      const id = parseInt(this.router);
      const start_date_kelompok = this.start_date;
      const end_date_kelompok = this.end_date;
      const nama = this.pelatihanById.nama;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            id: id,
            nama: nama,
            start_date_kelompok: start_date_kelompok,
            end_date_kelompok: end_date_kelompok
          }
        })
        .then(() => {
          this.$apollo.queries.pelatihanById.refetch();
          this.date = "";
          this.loading = false;
          this.$emit("false");
          Swal.fire({
            icon: "success",
            title: "Timeline telah di tambahkan",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
            position: "top"
          }).then(() => {
            this.$v.$reset();
            this.dialogKelompok = false;
          });
        });
    }
  }
};
</script>

<style scoped>
.zindex {
  z-index: 99;
}
._start {
  display: none;
}
._end {
  display: none;
}
</style>
