<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Kelompok</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="!$apollo.queries.allMentor.loading">
                <div v-if="allMentor.data.length < 1">
                  <v-btn text>Tambah coach</v-btn>
                </div>
                <div v-else>
                  <v-select
                    :options="allMentor.data"
                    :reduce="name => name.id"
                    v-model="selectedID"
                    @search="query => (find = query)"
                    label="name"
                    class="white"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
          <v-btn color="indigo" dark @click="assignMentor" v-if="selectedID">
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { EDIT_GROUP } from "../../../graphql/Group";
import { GROUP_BY_ID } from "../../../graphql/Event";
import { GET_MENTOR } from "../../../graphql/Mentor";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "selectMentor",
  apollo: {
    allMentor: {
      query: GET_MENTOR,
      variables() {
        return { page: 1, first: 10, search: this.find };
      },
      error(err) {
        console.log(err);
      }
    },
    grupById: {
      variables() {
        return {
          id: this.idGroup,
          find: ""
        };
      },
      query: GROUP_BY_ID
    }
  },

  mixins: [validationMixin],

  validations: {
    Mentor: { required }
  },

  props: ["dialog", "idGroup"],
  components: {
    vSelect
  },
  computed: {
    errMsg() {
      const errors = [];
      if (!this.$v.Mentor.$dirty) return errors;
      !this.$v.Mentor.required && errors.push("Coach harus dipilih");
      return errors;
    }
  },
  data: () => ({
    id: null,
    allMentor: [],
    Mentor: "",
    find: "",
    selectedID: null
  }),
  methods: {
    assignMentor() {
      const id = parseInt(this.idGroup);
      const mentor_id = parseInt(this.selectedID);
      this.$apollo
        .mutate({
          mutation: EDIT_GROUP,
          variables: {
            id: id,
            mentor_id: mentor_id
          }
        })
        .then(() => {
          this.$apollo.queries.grupById.refetch();
          this.$emit("false");
          this.selectedID = null;
          Swal.fire({
            icon: "success",
            title: "Anda telah menambahkan coach",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        });
    }
  }
};
</script>

<style></style>
