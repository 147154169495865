<template>
  <v-row>
    <v-dialog v-model="dialogModule" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Pilih modul</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="d-flex align-center flex-column">
              <div
                v-if="!allModul.data.length"
                class="d-flex"
              >

                <div>
                  <v-alert
                    class="ma-0 pa-2"
                    border="left"
                    colored-border
                    color="grey"
                  >
                    <p class="grey--text mb-0 ml-2">
                      Anda belum memiliki modul 
                    </p>
                  </v-alert>
                  <router-link to="/add-module" class="no-decor">
                    <v-btn text color="indigo"> Tambah modul sekarang. </v-btn>
                  </router-link>
                </div>
              </div>
              <v-col
                cols="12"
                sm="12"
                class="pa-0"
                v-else
                v-for="module in allModul.data"
                :key="module.id"
              >
                <v-alert
                  border="left"
                  colored-border
                  :color="
                    module.tipe == 'KELOMPOK'
                      ? 'red'
                      : module.tipe == 'INDIVIDU'
                      ? 'indigo'
                      : 'green'
                  "
                  elevation="2"
                  class="select pt-1 pb-1"
                  @click="selectModule(module)"
                >
                  <h5>{{ module.nama_modul }}</h5>
                  <small class="grey--text">tipe : {{ module.tipe }}</small>
                </v-alert>
              </v-col>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Keluar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  UPDATE_EVENT,
  CREATE_GROUP,
  EVENT_BY_ID
} from "../../../graphql/Event";
import { GET_MODULE } from "../../../graphql/Module";

export default {
  name: "selectModule",

  apollo: {
    allModul: {
      query: GET_MODULE,
      variables() {
        return { page: 1, first: 10 };
      },
      error(err) {
        console.log(err);
      }
    },
    pelatihanById: {
      variables() {
        return { id: this.router };
      },
      query: EVENT_BY_ID
    }
  },
  props: ["dialogModule", "router"],
  data: () => ({
    id: null,
    show: false,
    allModul: []
  }),

  methods: {
    selectModule(module) {
      const id = parseInt(this.router);
      const modul_id = parseInt(module.id);
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            id: id,
            modul_id: modul_id
          }
        })
        .then(data => {
          console.log(data.data.updatePelatihan.modul.tipe);
          this.$apollo.queries.pelatihanById.refetch();
          this.$emit("false");
          if (data.data.updatePelatihan.modul.tipe === "INDIVIDU") {
            this.addGroup();
          }
        });
    },
    addGroup() {
      const nama = this.pelatihanById.nama;
      const teacher_id = parseInt(localStorage.getItem("id"));
      const pelatihan_id = parseInt(this.router);
      this.$apollo
        .mutate({
          mutation: CREATE_GROUP,
          variables: {
            nama: nama,
            teacher_id: teacher_id,
            pelatihan_id: pelatihan_id
          }
        })
        .then(data => {
          console.log(data.data.createGrup);
          this.$apollo.queries.pelatihanById.refetch();
        });
    }
  }
};
</script>

<style>
.select {
  cursor: pointer;
}
.no-decor {
  text-decoration: none;
}
</style>
