<template>
  <div class="event-details bg-default">
    <nav class="pa-3 d-flex">
      <router-link to="/list-event" style="text-decoration: none">
        <v-btn dark fab small color="indigo">
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </router-link>
    </nav>
    <div
      style="height: 300px"
      class="bg-default d-flex justify-center align-center flex-column"
      v-if="proccess"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="indigo"
        indeterminate
        class="mb-2"
      ></v-progress-circular>
      <h4>Memuat...</h4>
    </div>
    <div v-else>
      <header class="cover-detail d-flex pa-3">
        <div class="card-head ma-3 pa-2 borad white shadow">
          <section class="pa-2 bg-default borad ma-1">
            <h2 class="indigo--text ml-2">
              {{ pelatihanById.nama }}
              <v-btn icon dark small color="cyan">
                <v-icon dark small @click="editPage()">mdi-pencil</v-icon>
              </v-btn>
            </h2>
            <div v-if="editNama" class="mt-2">
              <v-text-field
                class="indigo--text ml-2"
                v-model="pelatihanById.nama"
                label="Nama Pelatihan"
                hide-details
                @input="$v.nama.$touch()"
                style="width: 50%"
              >
              </v-text-field>
              <div class="d-flex mt-2">
                <v-btn color="indigo" dark class="mr-3" @click="updateEvent()">
                  Ubah
                </v-btn>

                <v-btn color="error" class="mr-4" @click="editNama = false">
                  Batal
                </v-btn>
              </div>
            </div>
          </section>
          <section class="pa-2 bg-default borad ma-1">
            <div
              v-if="!pelatihanById.modul"
              class="d-flex justify-space-between"
            >
              <v-btn text color="indigo">Pilih modul pelatihan</v-btn>
              <SelectModule
                v-bind:dialogModule="dialogModule"
                v-bind:router="$route.params.idEvent"
                v-on:false="dialogFalse(1)"
              />
              <v-btn fab dark small color="indigo" @click="dialogModule = true">
                <v-icon small dark> mdi-plus </v-icon>
              </v-btn>
            </div>
            <div v-else class="animate__animated animate__rubberBand">
              <h4 class="indigo--text ma-1 text-center">
                {{ pelatihanById.modul.nama_modul }}
              </h4>
              <v-divider></v-divider>
              <div class="d-flex">
                <section
                  class="divide ma-2 pa-2 orange darken-4 white--text rounded"
                >
                  <section>Fase</section>
                  <section>
                    <small
                      ><b>{{ pelatihanById.modul.tipe }}</b></small
                    >
                  </section>
                </section>
                <section
                  class="divide ma-2 pa-2 light-blue darken-1 white--text rounded"
                >
                  <section>Data Kasus</section>
                  <section>
                    {{ pelatihanById.modul.modul_kasus.length }}
                  </section>
                </section>
                <section
                  class="divide ma-2 pa-2 pink lighten-1 white--text rounded"
                >
                  <section>Tugas</section>
                  <section>
                    {{ pelatihanById.modul.modul_tugas.length }}
                  </section>
                </section>
                <section
                  class="divide ma-2 pa-2 indigo darken-1 white--text rounded"
                >
                  <section>Berita</section>
                  <section>{{ pelatihanById.modul.berita.length }}</section>
                </section>
              </div>
            </div>
          </section>
        </div>
        <div class="card-head ma-3">
          <div class="white shadow pa-3 borad mb-3">
            <v-alert dense border="left" type="info" class="mb-1">
              <strong>Perhatian</strong>
            </v-alert>
            <v-alert
              dense
              border="left"
              type="warning"
              class="mb-1"
              v-if="!pelatihanById.modul"
            >
              Pilih Modul
            </v-alert>
            <div v-if="pelatihanById.modul">
              <div v-if="!pelatihanById.start_date_individu">
                <v-alert
                  dense
                  border="left"
                  type="warning"
                  class="mb-1"
                  v-if="
                    pelatihanById.modul.tipe == 'INDIVIDU' ||
                      pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
                  "
                >
                  Tambah Timeline Individu
                </v-alert>
              </div>
              <div v-if="!pelatihanById.start_date_kelompok">
                <v-alert
                  dense
                  border="left"
                  type="warning"
                  class="mb-1"
                  v-if="
                    pelatihanById.modul.tipe == 'KELOMPOK' ||
                      pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
                  "
                >
                  Tambah Timeline Kelompok
                </v-alert>
              </div>
              <div>
                <div
                  v-if="
                    pelatihanById.modul.tipe == 'KELOMPOK' ||
                      pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
                  "
                >
                  <v-alert
                    dense
                    border="left"
                    type="warning"
                    class="mb-1"
                    v-if="!pelatihanById.grup"
                  >
                    Tambah Kelompok
                  </v-alert>
                </div>
              </div>
            </div>
          </div>
          <div class="white shadow pa-1 borad" v-if="pelatihanById.modul">
            <section
              class="pa-2 bg-default ma-1 borad"
              v-if="
                pelatihanById.modul.tipe == 'INDIVIDU' ||
                  pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
              "
            >
              <div
                class="d-flex justify-space-between"
                v-if="
                  pelatihanById.start_date_individu === null &&
                    pelatihanById.end_date_individu === null
                "
              >
                <v-btn text color="indigo">Tambah Jadwal Fase Individu</v-btn>
                <TimeIndividu
                  v-bind:dialogIndividu="dialogIndividu"
                  v-bind:router="$route.params.idEvent"
                  v-on:false="dialogFalse(2)"
                />
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  @click="dialogIndividu = true"
                >
                  <v-icon small dark> mdi-plus </v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-col cols="12" md="12" class="ma-0 pa-1">
                  <h4 class="indigo--text">
                    Fase Individu
                    <v-btn
                      @click="dialogIndividuUpdate = true"
                      icon
                      dark
                      small
                      color="cyan"
                    >
                      <v-icon dark small>mdi-pencil</v-icon>
                    </v-btn>
                  </h4>
                  <TimeIndividu
                    v-bind:dialogIndividuUpdate="dialogIndividuUpdate"
                    v-bind:router="$route.params.idEvent"
                    v-bind:time_start_individu="
                      pelatihanById.start_date_individu
                    "
                    v-bind:time_end_individu="pelatihanById.end_date_individu"
                    v-on:false="dialogFalse(5)"
                  />
                </v-col>
                <v-col cols="12" md="12" class="ma-0 pa-1">
                  <h4 class="indigo--text">
                    {{ pelatihanById.start_date_individu }} s/d
                    {{ pelatihanById.end_date_individu }}
                  </h4>
                </v-col>
              </div>
            </section>
            <section
              class="pa-2 bg-default borad ma-1"
              v-if="
                pelatihanById.modul.tipe == 'KELOMPOK' ||
                  pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
              "
            >
              <div
                class="d-flex justify-space-between"
                v-if="
                  pelatihanById.start_date_kelompok === null &&
                    pelatihanById.end_date_kelompok === null
                "
              >
                <v-btn text color="indigo">Tambah Jadwal Fase kelompok</v-btn>
                <TimeKelompok
                  v-bind:dialogKelompok="dialogKelompok"
                  v-bind:router="$route.params.idEvent"
                  v-on:false="dialogFalse(3)"
                />
                <v-btn
                  fab
                  :dark="!disablebtn"
                  small
                  :disabled="disablebtn"
                  color="indigo"
                  @click="dialogKelompok = true"
                >
                  <v-icon small dark> mdi-plus </v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-col cols="12" md="12" class="pa-1 ma-0">
                  <h4 class="indigo--text">
                    Fase Kelompok
                    <v-btn
                      color="cyan"
                      @click="dialogKelompokUpdate = true"
                      icon
                      dark
                      small
                    >
                      <v-icon dark small>mdi-pencil</v-icon>
                    </v-btn>
                  </h4>
                  <TimeKelompok
                    v-bind:dialogKelompokUpdate="dialogKelompokUpdate"
                    v-bind:router="$route.params.idEvent"
                    v-bind:time_end_kelompok="pelatihanById.end_date_kelompok"
                    v-bind:time_start_kelompok="
                      pelatihanById.start_date_kelompok
                    "
                    v-on:false="dialogFalse(6)"
                  />
                </v-col>
                <v-col cols="12" md="12" class="pa-1 ma-0">
                  <h4 class="indigo--text">
                    {{ pelatihanById.start_date_kelompok }} s/d
                    {{ pelatihanById.end_date_kelompok }}
                  </h4>
                </v-col>
              </div>
            </section>
          </div>
        </div>
      </header>

      <!-- Konfigurasi game -->
      <section class="white borad mx-5 px-2 py-2" v-if="pelatihanById.modul">
        <v-col cols="12" md="12"><h3>Konfigurasi game</h3></v-col>
        <v-row>
          <v-col cols="12" md="4"
            ><section class="bg-default pa-2 rounded">Avatar</section></v-col
          >
          <v-col cols="12" md="6">
            <v-select
              v-model="configGame.unlock_avatar"
              :items="allGame"
              item-text="nama"
              item-value="path"
              class="white"
              hide-details
              solo
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            pelatihanById.modul.tipe == 'INDIVIDU' ||
              pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
          "
        >
          <v-col cols="12" md="4"
            ><section class="bg-default pa-2 rounded">
              Kasus individu 1
            </section></v-col
          >
          <v-col cols="12" md="6">
            <v-select
              v-model="configGame.unlock_kasus_individu1"
              :items="allGame"
              item-text="nama"
              item-value="path"
              class="white"
              hide-details
              solo
            ></v-select
          ></v-col>
        </v-row>
        <v-row
          v-if="
            pelatihanById.modul.tipe == 'INDIVIDU' ||
              pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
          "
        >
          <v-col cols="12" md="4"
            ><section class="bg-default pa-2 rounded">
              Kasus individu 2
            </section></v-col
          >
          <v-col cols="12" md="6">
            <v-select
              v-model="configGame.unlock_kasus_individu2"
              :items="allGame"
              item-text="nama"
              item-value="path"
              class="white"
              hide-details
              solo
            ></v-select
          ></v-col>
        </v-row>
        <v-row
          v-if="
            pelatihanById.modul.tipe == 'KELOMPOK' ||
              pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
          "
        >
          <v-col cols="12" md="4"
            ><section class="bg-default pa-2 rounded">
              Kasus kelompok 1
            </section></v-col
          >
          <v-col cols="12" md="6">
            <v-select
              v-model="configGame.unlock_kasus_kelompok1"
              :items="allGame"
              item-text="nama"
              item-value="path"
              class="white"
              hide-details
              solo
            ></v-select
          ></v-col>
        </v-row>
        <v-row
          v-if="
            pelatihanById.modul.tipe == 'KELOMPOK' ||
              pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
          "
        >
          <v-col cols="12" md="4"
            ><section class="bg-default pa-2 rounded">
              Kasus kelompok 2
            </section></v-col
          >
          <v-col cols="12" md="6">
            <v-select
              v-model="configGame.unlock_kasus_kelompok2"
              :items="allGame"
              item-text="nama"
              item-value="path"
              class="white"
              hide-details
              solo
            ></v-select
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4"
            ><section class="bg-default pa-2 rounded">
              Informasi Pendukung
            </section></v-col
          >
          <v-col cols="12" md="6">
            <v-select
              v-model="configGame.unlock_berita"
              :items="allGame"
              item-text="nama"
              item-value="path"
              class="white"
              hide-details
              solo
            ></v-select
          ></v-col>
        </v-row>
        <v-col cols="12" md="2"
          ><v-btn class="indigo my-0 py-0" dark @click="assignGame"
            >terapkan</v-btn
          ></v-col
        >
        <v-col cols="12" md="12">
          <v-divider class="pa-0"></v-divider>
          <v-spacer></v-spacer>
          <h3 class="mx-2 mb-2">Informasi game</h3>
          <v-btn class="mx-2" @click="HintApta = true">apta</v-btn>
          <v-btn class="mx-2" @click="HintLigar = true">ligar</v-btn>
          <v-btn class="mx-2" @click="HintPinada = true">pinada</v-btn>
          <v-btn class="mx-2" @click="HintRaynor = true">raynor</v-btn>
          <v-btn class="mx-2" @click="HintSmara = true">smara</v-btn>
          <v-btn class="mx-2" @click="HintWiksa = true">wiksa</v-btn>
          <v-btn class="mx-2" @click="HintYoga = true">yoga</v-btn>
        </v-col>
        <HintApta v-bind:HintApta="HintApta" @false="closeDialog" />
        <HintLigar v-bind:HintLigar="HintLigar" @false="closeDialog" />
        <HintPinada v-bind:HintPinada="HintPinada" @false="closeDialog" />
        <HintRaynor v-bind:HintRaynor="HintRaynor" @false="closeDialog" />
        <HintSmara v-bind:HintSmara="HintSmara" @false="closeDialog" />
        <HintWiksa v-bind:HintWiksa="HintWiksa" @false="closeDialog" />
        <HintYoga v-bind:HintYoga="HintYoga" @false="closeDialog" />
      </section>
      <div
        class="bg-default d-flex align-center justify-center"
        style="height: 400px"
        v-if="!pelatihanById.modul"
      >
        <h1>Harap lengkapi pelatihan</h1>
      </div>
      <div v-if="pelatihanById.modul !== null">
        <div
          class="temp-group pa-3"
          v-if="
            pelatihanById.modul.tipe == 'KELOMPOK' ||
              pelatihanById.modul.tipe == 'INDIVIDUKELOMPOK'
          "
        >
          <nav class="mb-5">
            <v-btn text color="indigo" @click="dialogGroup = true">
              Tambah kelompok
              <v-btn color="indigo" fab small dark class="ml-2"
                ><v-icon dark small>mdi-plus</v-icon></v-btn
              >
            </v-btn>
            <AddGroup
              v-bind:dialogGroup="dialogGroup"
              v-bind:router="$route.params.idEvent"
              v-on:false="dialogFalse(4)"
            />
          </nav>
          <div
            style="height: 300px"
            class="bg-default d-flex justify-center align-center flex-column"
            v-if="
              undefined !== pelatihanById.grup && pelatihanById.grup.length < 1
            "
          >
            <h3>Pelatihan ini belum memiliki kelompok</h3>
          </div>
          <div class="list-group" v-else>
            <section
              class="card-group borad pa-3 white--text"
              v-for="(grup, idx) in pelatihanById.grup"
              :key="idx"
            >
              <div
                style="height: 80%"
                class="d-flex align-center justify-center"
              >
                <div>
                  <h1>{{ grup.nama }}</h1>
                  <v-icon dark>mdi-account-multiple</v-icon>
                  {{ grup.user.length }} Peserta
                </div>
              </div>
              <div class="d-flex justify-end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="viewGroup(grup)"
                      ><v-icon>mdi-eye</v-icon></v-btn
                    >
                  </template>
                  <span>Lihat detail kelompok</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteGroup(grup)"
                    >
                      <v-icon v-if="!deleting">mdi-delete</v-icon>
                      <v-icon v-else>mdi-delete-empty</v-icon>
                    </v-btn>
                  </template>
                  <span>hapus kelompok</span>
                </v-tooltip>
              </div>
            </section>
          </div>
        </div>
        <div v-else-if="pelatihanById.modul.tipe == 'INDIVIDU'">
          <div v-if="pelatihanById.grup !== null">
            <AutoCreateGroup v-bind:idGroup="pelatihanById.grup[0].id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EVENT_BY_ID,
  DELETE_GROUP,
  UPDATE_EVENT,
  ALL_GAME
} from "../../graphql/Event";
import SelectModule from "../Teacher/Modal/selectModule";
import TimeIndividu from "../Teacher/Modal/faseIndividu";
import TimeKelompok from "../Teacher/Modal/faseKelompok";
import AddGroup from "../Teacher/Modal/addGroup";
import AutoCreateGroup from "../Teacher/autoCreateGroup";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";

import moment from "moment";
import HintApta from "../_base/Game/HintApta.vue";
import HintLigar from "../_base/Game/HintLigar.vue";
import HintPinada from "../_base/Game/HintPinada.vue";
import HintRaynor from "../_base/Game/HintRaynor.vue";
import HintSmara from "../_base/Game/HintSmara.vue";
import HintWiksa from "../_base/Game/HintWiksa.vue";
import HintYoga from "../_base/Game/HintYoga.vue";

export default {
  apollo: {
    pelatihanById: {
      variables() {
        return { id: this.$route.params.idEvent };
      },
      query: EVENT_BY_ID,
      error(error) {
        let err = Object.values(error.graphQLErrors[0]);
        this.errMsg = err[0];
      }
    },
    allGame: {
      query: ALL_GAME,
      error(error) {
        let err = Object.values(error.graphQLErrors[0]);
        this.errMsg = err[0];
      }
    }
  },
  name: "eventDetails",
  components: {
    SelectModule,
    TimeIndividu,
    TimeKelompok,
    AddGroup,
    AutoCreateGroup,
    HintApta,
    HintLigar,
    HintPinada,
    HintRaynor,
    HintSmara,
    HintWiksa,
    HintYoga
    // DatePicker,
    // vSelect,
  },
  mixins: [validationMixin],
  validations: {
    nama: { required }
  },
  data() {
    return {
      eventId: null,
      pelatihanById: [],
      allGame: [],
      dialogModule: false,
      dialogIndividu: false,
      dialogKelompok: false,
      dialogIndividuUpdate: false,
      dialogKelompokUpdate: false,
      dialogGroup: false,
      deleting: false,
      errMsg: null,
      val: "30 December 2020",
      editNama: false,
      now: new Date().toISOString().slice(0, 10),
      // dialog hint game
      HintApta: false,
      HintLigar: false,
      HintPinada: false,
      HintRaynor: false,
      HintSmara: false,
      HintWiksa: false,
      HintYoga: false,
      fromEvt: true
    };
  },
  computed: {
    startDateIndividu() {
      return (
        moment(this.now, "YYYY-MM-DD")
          // .add(1, "days")
          .format("YYYY-MM-DD")
      );
    },
    endDateIndividu() {
      return (
        moment(this.pelatihanById.end_date_individu, "YYYY-MM-DD")
          // .add(1, "days")
          .format("YYYY-MM-DD")
      );
    },
    startDateKelompok() {
      return moment(this.pelatihanById.start_date_kelompok, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    endDateKelompok() {
      return (
        moment(this.pelatihanById.end_date_kelompok, "YYYY-MM-DD")
          // .add(1, "days")
          .format("YYYY-MM-DD")
      );
    },
    disablebtn() {
      let disable = false;
      if (this.pelatihanById.modul.tipe === "INDIVIDUKELOMPOK") {
        if (this.pelatihanById.start_date_individu === null) {
          disable = true;
        }
      }
      return disable;
    },
    proccess() {
      return this.$apollo.queries.pelatihanById.loading;
    },
    namaErr() {
      const errors = [];
      if (!this.$v.nama.$dirty) return errors;
      !this.$v.nama.required && errors.push("Nama Pelatihan harus di isi!");
      return errors;
    },
    configGame() {
      let data = null;
      if (!this.proccess) {
        data = JSON.parse(this.pelatihanById.game);
      }
      return data;
    }
  },
  watch: {
    pelatihanById(val) {
      if (val.start_date_individu !== null) {
        const [year, month, day] = val.start_date_individu.split("-");
        val.start_date_individu = `${day}-${month}-${year}`;
      }
      if (val.end_date_individu !== null) {
        const [year, month, day] = val.end_date_individu.split("-");
        val.end_date_individu = `${day}-${month}-${year}`;
      }
      if (val.start_date_kelompok !== null) {
        const [year, month, day] = val.start_date_kelompok.split("-");
        val.start_date_kelompok = `${day}-${month}-${year}`;
      }
      if (val.end_date_kelompok !== null) {
        const [year, month, day] = val.end_date_kelompok.split("-");
        val.end_date_kelompok = `${day}-${month}-${year}`;
      }
    }
  },
  methods: {
    editPage() {
      console.log(this.pelatihanById);
      this.editNama = true;
      console.log(this.allGame);
    },
    dialogFalse(qna) {
      switch (qna) {
        case 1:
          this.dialogModule = false;
          break;
        case 2:
          this.dialogIndividu = false;
          break;
        case 3:
          this.dialogKelompok = false;
          break;
        case 4:
          this.dialogGroup = false;
          break;
        case 5:
          this.dialogIndividuUpdate = false;
          break;
        case 6:
          this.dialogKelompokUpdate = false;
          break;
      }
    },
    viewGroup(admin) {
      this.$router.push(`/group-detail/${admin.id}`);
    },
    updateEvent() {
      const id = this.pelatihanById.id;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            id: id,
            nama: this.pelatihanById.nama,
            teacher_id: this.pelatihanById.teacher_id,
            modul_id: this.pelatihanById.modul_id
          }
        })
        .then(() => {
          this.$apollo.queries.pelatihanById.refetch();
          this.loading = false;
          this.editNama = false;
          Swal.fire({
            icon: "success",
            title: "Pelatihan berhasil diubah!",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          }).then(() => {
            this.$emit("false");
            this.$v.$reset();
            this.editNama = false;
          });
        });
    },
    deleteGroup(group) {
      this.deleting = true;
      Swal.fire({
        title: "Yakin hapus kelompok?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_GROUP,
              variables: {
                id: group.id
              }
            })
            .then(() => {
              this.$apollo.queries.pelatihanById.refetch();
              this.deleting = false;
            });
          Swal.fire({
            icon: "success",
            title: "Kelompok telah terhapus",
            toast: true,
            showConfirmButton: false,
            position: "top",
            timer: 3000
          });
        } else {
          this.deleting = false;
        }
      });
    },
    get_game(id) {
      this.src = false;
      this.id_game = id;
      this.$apollo.queries.allGame.refetch();
      console.log(id);
    },
    assignGame() {
      this.$store
        .dispatch("assignGame", {
          pelatihan_id: this.pelatihanById.id,
          unlock_avatar: this.configGame.unlock_avatar,
          unlock_kasus_individu1: this.configGame.unlock_kasus_individu1,
          unlock_kasus_individu2: this.configGame.unlock_kasus_individu2,
          unlock_kasus_kelompok1: this.configGame.unlock_kasus_kelompok1,
          unlock_kasus_kelompok2: this.configGame.unlock_kasus_kelompok2,
          unlock_berita: this.configGame.unlock_berita,
          unlock_tugas: this.configGame.unlock_tugas
        })
        .then(() => {
          this.$apollo.queries.pelatihanById.refetch();
          Swal.fire({
            icon: "success",
            title: "Game telah diterapkan!",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          }).then(() => {});
        });
    },
    closeDialog() {
      this.HintApta = false;
      this.HintLigar = false;
      this.HintPinada = false;
      this.HintRaynor = false;
      this.HintSmara = false;
      this.HintWiksa = false;
      this.HintYoga = false;
    }
  }
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
}
.borad {
  border-radius: 10px;
}
.borbox {
  box-sizing: border-box;
}
.event-details {
  width: 100%;
}
.cover-detail {
  width: 100%;
}
.shadow {
  box-shadow: 0px 0px 24px 2px rgba(0, 0, 0, 0.06);
}
.card-head {
  width: 60%;
  height: max-content;
}
.card-head:nth-child(2) {
  width: 40%;
}
.list-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.divide {
  width: calc(100% / 4);
}
.card-group {
  width: 300px;
  height: 200px;
  background: purple;
  margin: 15px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.27);
}
</style>
